<template>
    <!-- /.content -->
    <div>
            <button @click="handleClick">Test</button>
            <Pdfpreview :file="file" :open="open" />
            </div>
</template>
<script>
//import PDFJSViewer from '@/components/PDFJSViewer'
import Pdfpreview from '@/components/Pdfpreview'
export default {
    name: 'Pdfviewer',
      components: {
        //PDFJSViewer, 
        Pdfpreview
      },
    data() {
        return {
            errors: [],
            open:false,
            file:'/plugins/pdfjs/test.pdf',
            form: {
                name: '',
                username: '',
                email:''
            }
        }
    },
    methods: {
        handleClick(e) {
            this.open = !this.open;
            console.log('click', this.open)
        }
    }
}
</script>