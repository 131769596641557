<template>
<div>
  <iframe  width="100%" style="min-height: 500px;" :src="`${getFilePath}`" ></iframe>
</div>
</template>

<script>
export default {
  name: 'PDFJSViewer',
  props: ['file'],
  data: function () {
    return {
    }
  },
  computed:{ 
    getFilePath: function() {
      if(this.file!==''){
          return '/plugins/pdfjs/viewer.html?file=' + this.file;
      }
      return '/plugins/pdfjs/viewer.html';
    }
  }
}
</script>
<style scoped>
</style>