<template>
    <div class="modal show" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Preview</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="padding:0 !important;min-height: 500px;">
                        <PDFJSViewer :file="file" />
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import PDFJSViewer from '@/components/PDFJSViewer'
import $ from 'jquery';
export default {
    name: 'Pdfpreview',
    props: {
        open: Boolean,
        file: String
    },
    components: {
        PDFJSViewer
    },
    methods: {
        showDialog(e) {
            $(this.refs.formDialog).modal(e?'show':'hide');
        }
    },
    watch: {
        open: function(old, val) {
            //
            $(this.$refs.formDialog).modal('show');
            console.log('change', val)
        }
    },
    data: function() {
        return {
            //file:'/plugins/pdfjs/test.pdf'
        }
    },
    computed: {}
}
</script>
<style scoped>
</style>